import React, { Component } from 'react';
// import { jalaliDate } from '../utility/Date';
import Axios from 'axios';
import Swal from 'sweetalert2';

class Orders extends Component {
    state = {
        fetching: false,
        searchPhoneNO: null,
        searchFullName: null,
        userlist: null,
        previewuserdata: null,
        selectedIndex: null
    }

    Date = (date) => {
        const year = date.slice(0, 4);
        const month = date.slice(5, 7);
        const day = date.slice(8, 10);
        // return jalaliDate(year, month, day);
    }

    EnToPerNumFunc = (input) => {
        let inpNum = input.toString();
        const perNums = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
        const EnNumbersRegExp = [/0/g, /1/g, /2/g, /3/g, /4/g, /5/g, /6/g, /7/g, /8/g, /9/g];
        for (let i = 0; i < 10; i++) {
            inpNum = inpNum.replace(EnNumbersRegExp[i], perNums[i]);
        };
        return inpNum;
    }

    UserStatusFunc = (UserStatusID) => {
        switch (UserStatusID) {
            case 1: {
                return (<span style={{ color: 'green' }}>فعال</span>);
            }
            case 2: {
                return (<span style={{ color: 'red' }}>غیرفعال</span>);
            }
            case 3: {
                return (<span style={{ color: '#fe9c01' }}>در انتظار بررسی</span>);
            }
            case 4: {
                return (<span style={{ color: 'red', fontSize: '14px' }}>رد شده به علت<br />نقص اطلاعات</span>);
            }
            case 5: {
                return (<span style={{ color: 'red', fontSize: '14px' }}>رد شده به علت عدم<br />احراز صحت اطلاعات</span>);
            }
        }
    }

    UserDataPrevFunc = (item, index, mode) => {
        this.setState({ previewuserdata: item, selectedIndex: index });
        switch (mode) {
            case "view":
                window.open('/facture?pre=0&s=shop&oid=' + item.order_id, '_blank', 'noopener,noreferrer');

                break;
            case "delete":

                break;
            case "edit":
                document.getElementById("Name1").value = item.Name1
                document.getElementById("Name2").value = item.Name2
                document.getElementById("Stadt").value = item.Stadt
                document.getElementById("Strasse").value = item.Strasse
                document.getElementById("PLZ").value = item.PLZ
                document.getElementById("Land").value = item.Land
                document.getElementById("Tel1").value = item.Tel1
                document.getElementById("Tel2").value = item.Tel2
                document.getElementById("Fax").value = item.Fax
                document.getElementById("WWW").value = item.WWW
                document.getElementById("TaxID").value = item.TaxID
                document.getElementById("Email").value = item.Email

                break;


            default:
                break;
        }
        // document.getElementById("AdUserListSituationSelectID").selectedIndex = data.UserStatusID - 1;
    }

    UserDataEditFunc = () => {
        const token = window.sessionStorage.getItem('SatCPToken');
        const username = this.state.previewuserdata.Username;
        const statusID = null //document.getElementById("AdUserListSituationSelectID").value;
        const searchPhoneNO = this.state.searchPhoneNO;
        const searchFullName = this.state.searchFullName;

        Axios.post('http://ms.esay.ir/user/updateAdUser.php', { "Username": username, "StatusID": statusID }, { headers: { Token: token } }).then(Response => {
            if (Response.data.code === 0) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    text: Response.data.messsage,
                    showConfirmButton: false,
                    timer: 3500,
                });

                this.setState({ fetching: true });
                Axios.post('http://ms.esay.ir/user/getAdUserList.php', { "phone": searchPhoneNO, "fullname": searchFullName }, { headers: { Token: token } }).then(Response => {
                    if (Response.data.code === 0) {
                        this.setState({ fetching: false, userlist: Response.data.userlist })
                    }
                })
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    text: Response.data.message,
                    showConfirmButton: false,
                    timer: 3500,
                });
            }
        })
    }

    SearchFunc = () => {

        const CustomerNummer = "1"
        const CustomerID = "1"
        const OrderID = "1"
        const OrderModID = "1"


        const Token = window.localStorage.getItem('MainLogInTOKEN');
        // console.log(email)
        if (CustomerNummer !== "" || CustomerID !== "" || OrderID !== "" || OrderModID !== 0) {
            // console.log(Token)
            // this.setState({ fetching: true, searchPhoneNO: email, searchFullName: name });
            Axios.post('https://b2b.yborhani.de/api/shop/getOrders.php', { "CustomerNummer": CustomerNummer, "CustomerID": CustomerID, "OrderID": OrderID, "OrderMod": OrderModID }, { headers: { 'Token': Token } }).then(Response => {
                // console.log(Response.data)
                if (Response.data.code === 0) {
                    this.setState({ fetching: false, userlist: Response.data.orders })
                } else if (Response.data.code === 5) {
                    window.localStorage.setItem('MainLogInTOKEN', "")
                    window.location = "/login"
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        text: Response.data.message,
                        showConfirmButton: false,
                        timer: 3500,
                    });
                }
            })
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                text: 'Please enter somethings.',
                showConfirmButton: false,
                timer: 3500,
            });
            // window.focus();
            // document.getElementById("btnSearch").current.blur();
        }
    }

    ContentFunc = () => {
        const data = this.state.userlist;

        if (data && data.length !== 0) { //
            return (<table className="styled-table">
                <thead>
                    <tr>
                        <th>&nbsp;&nbsp;Order ID</th>
                        <th>Customer ID</th>
                        <th>Customer Name</th>
                        <th>Date Modified</th>
                        <th>Status</th>
                        <th style={{ width: '100px', textAlign: 'center' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                        return (<tr key={index}>
                            <td>&nbsp;&nbsp;{item.order_id}</td>
                            <td>{item.customer_id}</td>
                            <td>{item.firstname} {item.lastname}</td>
                            <td>{(item.date_modified)}</td>
                            <td>{(item.order_status_id)}</td>

                            <td>
                                <button type="button" className="btn btn-link" onClick={() => this.UserDataPrevFunc(item, index, "view")} style={{ width: '40px', color: '#fe9c01' }}><i className="fas fa-eye"></i></button>
                                {/* <span>&nbsp;&nbsp;</span>
                                <button type="button" className="btn btn-link " onClick={() => this.UserDataPrevFunc(item, index, "edit")} data-toggle="modal" data-target="#EditDataModal" style={{ color: '#119c01', width: '30px' }}><i className="fas fa-edit"></i></button> */}
                                <span>&nbsp;&nbsp;</span>
                                <button type="button" className="btn btn-link " onClick={() => this.UserDataPrevFunc(item, index, "delete")} data-toggle="modal" data-target="#DeleteDataModal" style={{ color: '#ff0000', width: '40px' }}><i className="fas fa-check-circle"></i></button>

                            </td>
                        </tr>)
                    })}
                </tbody>
            </table >)
        } else {
            return (<div style={{ width: '100%', textAlign: 'center', height: '90px', margin: '20px 0' }}>
                <span style={{ color: 'gray', fontSize: '18px', display: 'block' }}>Record not found.</span>
            </div>)
        }
    }

    deleteOrder = (ID) => {
        // delete customer from server
        const Token = window.localStorage.getItem('MainLogInTOKEN');

        Axios.post('https://b2b.yborhani.de/api/deleteOrder.php', { "ID": ID }, { headers: { 'Token': Token } }).then(Response => {
            if (Response.data.code === 0) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    text: Response.data.message,
                    showConfirmButton: false,
                    timer: 3500,
                });
            } else if (Response.data.code === 5) {
                window.localStorage.setItem('MainLogInTOKEN', "")
                window.location = "/login"
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    text: Response.data.message,
                    showConfirmButton: false,
                    timer: 3500,
                });
            }
        })

        // remove item from data list
        try {
            this.setState({ userlist: this.state.userlist.filter((item) => item.ID !== ID) });
        } catch (error) {
            console.log(error)
        }
    }

    editOrder = (item, index) => {
        // delete customer from server
        const Token = window.localStorage.getItem('MainLogInTOKEN');

        // this.state.userlist[index].Name1 = document.getElementById("Name1").value
        // this.state.userlist[index].Name2 = document.getElementById("Name2").value
        // this.state.userlist[index].Stadt = document.getElementById("Stadt").value
        // this.state.userlist[index].Strasse = document.getElementById("Strasse").value
        // this.state.userlist[index].PLZ = document.getElementById("PLZ").value
        // this.state.userlist[index].Land = document.getElementById("Land").value
        // this.state.userlist[index].Tel1 = document.getElementById("Tel1").value
        // this.state.userlist[index].Tel2 = document.getElementById("Tel2").value
        // this.state.userlist[index].Fax = document.getElementById("Fax").value
        // this.state.userlist[index].WWW = document.getElementById("WWW").value
        // this.state.userlist[index].TaxID = document.getElementById("TaxID").value
        // this.state.userlist[index].Email = document.getElementById("Email").value

        Axios.post('https://b2b.yborhani.de/api/editOrder.php', item, { headers: { 'Token': Token } }).then(Response => {
            console.log(Response.data)
            if (Response.data.code === 0) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    text: "Successfully done!",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else if (Response.data.code === 5) {
                window.localStorage.setItem('MainLogInTOKEN', "")
                window.location = "/login"
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    text: Response.data.message,
                    showConfirmButton: false,
                    timer: 3500,
                });
            }
        })

        // remove item from data list
        try {
            this.setState({ userlist: this.state.userlist });
        } catch (error) {
            console.log(error)
        }
    }

    getOrderMode = (Mode) => {
        switch (Mode) {
            case "1":
                return "Invoice";
            case "2":
                return "Comition";
            case "3":
                return "PreInvoice";
            default:
                return "Unknown";
        }
    }

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <h2>Shop Orders</h2>
                        {/* <ul className="nav navbar-nav ml-auto">
                            <li className="nav-item">
                                <button type="button" className="btn btn-warning" data-toggle="modal" data-target="#NewDataModal" style={{ width: '105px', color: '#ffffff' }}><i className="fas fa-plus"></i>&nbsp;&nbsp;New</button>
                            </li>
                        </ul> */}
                    </div>

                </nav>

                <div class="form-row">
                    <div class="form-group col-md-3">
                        <input type="text" class="form-control" id="searchOrderID" placeholder="Order ID" />
                    </div>

                    <div class="form-group col-md-3">
                        <input type="text" class="form-control" id="searchCustomerID" placeholder="Customer ID" />
                    </div>

                    <div class="form-group col-md-2">
                        <input type="text" class="form-control" id="searchCustomerNummer" placeholder="Customer Nummer" />
                    </div>

                    <div class="form-group col-md-2">
                        {/* <select id="searchSelectOrderStatus" class="form-control">
                            <option value="0" selected>Select Status ...</option>
                            <option value="3">PreInvoice</option>
                            <option value="1">Invoiced</option>
                            <option value="2">Comition</option>
                        </select> */}
                        <select class="custom-select mr-sm-2" id="selectOrderMode" style={{ width: '200px' }}>
                            <option value="0" selected>Select Status ...</option>
                            <option value="1">Invoice</option>
                            <option value="2">Comition</option>
                            <option value="3">Reject</option>
                            <option value="4">Return</option>
                        </select>
                    </div>


                    <div class="form-group col-md-2">
                        <button id="btnSearch" type="button" onClick={this.SearchFunc} className="btn btn-warning" style={{ width: "100%", height: "40px", color: 'white', padding: '0' }}><i className="fas fa-search"></i>&nbsp;&nbsp;Search</button>
                    </div>


                </div>

                <div className="rounded">
                    {(!this.state.fetching) ? (this.ContentFunc()) : (<div style={{ textAlign: 'center', margin: '10px 0' }}>
                        <div className="spinner-border text-warning" style={{ width: '90px', height: '90px' }} role="status">
                            <span className="sr-only" ></span>
                        </div>
                    </div>)}
                </div>

                <div className="modal fade" id="DeleteDataModal" tabIndex="-1" role="dialog" aria-labelledby="DeleteDataModal_Label" aria-hidden="true" >
                    <div className="modal-dialog modal-dialog-centered" role="document" >
                        <div className="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalCenterTitle">Checkout Order!</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body p-3">
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: '40px 40px 40px 40px' }}>
                                    <div>
                                        <span style={{ color: '#009879', fontWeight: 'bold' }}>ID:&nbsp;&nbsp;&nbsp;</span>
                                        <span>{this.state.previewuserdata && this.state.previewuserdata.ID}</span>
                                    </div>

                                    <div>
                                        <span style={{ color: '#009879', fontWeight: 'bold' }}>CID:&nbsp;&nbsp;&nbsp;</span>
                                        <span>{this.state.previewuserdata && this.state.previewuserdata.CID}</span>
                                    </div>

                                    <div>
                                        <span style={{ color: '#009879', fontWeight: 'bold' }}>TotalPrice:&nbsp;&nbsp;&nbsp;</span>
                                        <span>{this.state.previewuserdata && this.state.previewuserdata.TotalPrice}</span>
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer" style={{ marginTop: '15px', textAlign: 'center' }}>
                                <button data-dismiss="modal" className="btn" onClick={() => this.deleteOrder(this.state.previewuserdata.ID)} style={{ color: 'white', backgroundColor: '#fe9c01', width: '100px' }}>Checkout</button>                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="EditDataModal" tabIndex="-1" role="dialog" aria-labelledby="EditDataModal_Label" aria-hidden="true" >
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document" >
                        <div className="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalCenterTitle">Edit Order Status - Order ID: <span style={{ color: "green" }}>{this.state.previewuserdata && this.state.previewuserdata.ID}</span></h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body p-3">

                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="Name1">Customer ID</label>
                                        <input type="text" maxLength="50" class="form-control" id="Name1" disabled="disabled" placeholder="CID" value={this.state.previewuserdata && this.state.previewuserdata.CID} />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="Name2">Date</label>
                                        <input type="text" maxLength="50" class="form-control" id="Name2" disabled="disabled" value={this.state.previewuserdata && this.state.previewuserdata.Createdatetime} placeholder="Date" />
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="Name3">Current Status</label>
                                        <input type="text" maxLength="50" class="form-control" id="Name3" disabled="disabled" placeholder="CID" value={this.getOrderMode(this.state.previewuserdata && this.state.previewuserdata.Mode)} />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="Name4">New Status</label>
                                        <select id="editSelectOrderStatus" class="form-control">
                                            <option value="0" selected>Select Status ...</option>
                                            <option value="3">PreInvoice</option>
                                            <option value="1">Invoiced</option>
                                            <option value="2">Comition</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer" style={{ marginTop: '15px', textAlign: 'center' }}>
                                <button data-dismiss="modal" className="btn" onClick={() => this.editOrder(this.state.previewuserdata, this.state.selectedIndex)} style={{ color: 'white', backgroundColor: '#fe9c01', width: '100px' }}>Save</button>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="modal fade" id="NewDataModal" tabIndex="-1" role="dialog" aria-labelledby="NewDataModal_Label" aria-hidden="true" >
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document" >
                        <div className="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalCenterTitle">Add New Customer<span style={{ color: "green" }}></span></h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body p-3">

                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="newName1">Name1</label>
                                        <input type="text" maxLength="50" class="form-control" id="newName1" placeholder="Name1" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="newName2">Name2</label>
                                        <input type="text" maxLength="50" class="form-control" id="newName2" placeholder="Name2" />
                                    </div>
                                </div>
                                <hr />
                                <div class="form-row">
                                    <div class="form-group col-md-4">
                                        <label for="newStrasse">Strasse</label>
                                        <input type="text" class="form-control" id="newStrasse" placeholder="Strasse" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="newStadt">Stadt</label>
                                        <input type="text" class="form-control" id="newStadt" placeholder="Stadt" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="newLand">Land</label>
                                        <input type="text" class="form-control" id="newLand" placeholder="Land" />
                                    </div>
                                </div>
                                <hr />
                                <div class="form-row">
                                    <div class="form-group col-md-4">
                                        <label for="newPLZ">PLZ</label>
                                        <input type="text" class="form-control" id="newPLZ" placeholder="PLZ" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="newTel1">Tel1</label>
                                        <input type="text" class="form-control" id="newTel1" placeholder="Tel1" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="newTel2">Tel2</label>
                                        <input type="text" class="form-control" id="newTel2" placeholder="Tel2" />
                                    </div>
                                </div>
                                <hr />

                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="newEmail">Email</label>
                                        <input type="text" class="form-control" id="newEmail" placeholder="Email" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="newWWW">WWW</label>
                                        <input type="text" class="form-control" id="newWWW" placeholder="WWW" />
                                    </div>
                                </div>
                                <hr />

                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="newFax">Fax</label>
                                        <input type="text" class="form-control" id="newFax" placeholder="Fax" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="newTaxID">TaxID</label>
                                        <input type="text" class="form-control" id="newTaxID" placeholder="TaxID" />
                                    </div>
                                </div>


                            </div>
                            <div className="modal-footer" style={{ marginTop: '15px', textAlign: 'center' }}>
                                <button data-dismiss="modal" className="btn" onClick={() => this.newCustomer(this.state.previewuserdata, this.state.selectedIndex)} style={{ color: 'white', backgroundColor: '#fe9c01', width: '100px' }}>Save</button>
                            </div>


                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export default Orders;