import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

class Dashboard extends React.Component {

    state = {
        mobile: ""
    };


    login = () => {
        const token = window.sessionStorage.getItem('SatCPToken');
        const username = document.getElementById("txtusername").value;
        const password = document.getElementById("txtpassword").value;

        axios.post('https://b2b.yborhani.de/api/login.php', { "username": username, "password": password }, { headers: { Token: password } }).then(Response => {
            if (Response.data.code === 0) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    text: Response.data.message,
                    showConfirmButton: false,
                    timer: 3500,
                });

                window.localStorage.setItem('MainLogInTOKEN', Response.data.token);
                document.location = '/';

            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    text: Response.data.message,
                    showConfirmButton: false,
                    timer: 3500,
                });
            }
        })
    }

    render() {
        return (
            <div>
                <div className="container relativity">
                    <h3 className="text-left my-3" style={{ paddingTop: '0px' }}>Dashboard</h3>
                    {/* <div className="card card-default text-center">
                        <div className="card-header font-18"> </div>
                        <div className="card-block" style={{ height: '50px' }}>
                            Under construction!
                        </div>

                        <div className="card-footer">

                        </div>
                    </div> */}
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <div class="card" style={{ height: '300px' }}>
                                <div class="card-header">
                                    Customers
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title">Featured:</h5>
                                    <p class="card-text">ADD, EDIT, VIEW, REMOVE customers info</p>
                                    <p class="card-text">Count: 12931#</p>
                                </div>
                            </div>

                        </div>
                        <div class="form-group col-md-6">

                            <div class="card" style={{ height: '300px' }}>
                                <div class="card-header">
                                    Inventory
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title">Featured:</h5>
                                    <p class="card-text">ADD, EDIT, VIEW, REMOVE Products info from inventory</p>
                                    <p class="card-text">All product count: 14900#</p>
                                    <p class="card-text">Invoiced product: 1490#</p>
                                    <p class="card-text">Comition product: 1931#</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">

                            <div class="card" style={{ height: '300px' }}>
                                <div class="card-header">
                                    Orders
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title">Featured:</h5>
                                    <p class="card-text">ADD, VIEW, REMOVE Orders info</p>
                                    <p class="card-text">PreInvoices: 3#</p>
                                    <p class="card-text">Invoice: 230#</p>
                                    <p class="card-text">Comition: 123#</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div class="card" style={{ height: '300px' }}>
                                <div class="card-header">
                                    Accounting
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title">Featured:</h5>
                                    <p class="card-text">ADD, VIEW, AUTO GENERATE Factued info</p>
                                    <p class="card-text">All Amount: 3900 USD and 12390 ERU</p>
                                    <p class="card-text">All Amount this year: 300 USD and 2390 ERU</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Dashboard;