import React, { Component } from 'react';
import '../Style/Dashboard.css'
import Customers from "../Moduls/Customers.js";
import Dashboard from "../Moduls/Dashboard.js"
import OrderList from "../Moduls/OrderList.js"
import OrderNew from "../Moduls/OrderNew.js"
import InventoryNew from "../Moduls/InventoryNew.js"
import InventoryList from "../Moduls/InventoryList.js"
import AccountingList from '../Moduls/AccountingList'
import AccountingNew from '../Moduls/AccountingNew'
import ShopOrders from '../Moduls/Shop/Orders'

import Axios from 'axios';



class MainPage extends React.Component {

    state = {
        logedin: false,
        Page: "dashboard"
    };

    componentDidMount() {
        this.checkToken()
    }

    checkToken = () => {
        const Token = window.localStorage.getItem('MainLogInTOKEN');
        if (Token) {
            Axios.get('https://b2b.yborhani.de/api/token.php', {
                withCredentials: false,
                headers: {
                    'Token': Token
                }
            }).then(Response => {
                if (Response.data.code === 0) {
                    this.setState({ logedin: true });
                } else {
                    this.setState({ logedin: false });
                    window.location = "/login"
                }
            })
        } else {
            this.setState({ logedin: false });
            window.location = "/login"
        }

    }

    collapseMenu = () => {
        document.getElementById("sidebar").classList.toggle("active");
        // $('#sidebar').toggleClass('active');
    }

    logout = () => {
        this.setState({ logedin: false });
        window.localStorage.setItem('MainLogInTOKEN', "")
        window.location = "/login"
    }

    setModuls = (page) => {
        this.setState({ Page: page });
    }

    setPage = () => {

        switch (this.state.Page) {

            case "dashboard":
                return (<Dashboard />)
            case "shop-orders":
                return (<ShopOrders />)
            case "customer":
                return (<Customers />)
            case "order-list":
                return (<OrderList />)
            case "order-new":
                return (<OrderNew />)
            case "inventory-list":
                return (<InventoryList />)
            case "inventory-new":
                return (<InventoryNew />)
            case "accounting-list":
                return (<AccountingList />)
            case "accounting-new":
                return (<AccountingNew />)
            default:
                return (<Dashboard />)
        }
    }


    render() {
        if (this.state.logedin) {
            return (
                <div className="wrapper">
                    <nav id="sidebar">
                        <div className="sidebar-header">
                            <h3>Facture B2B</h3>
                            <strong>B2B</strong>
                        </div>

                        <ul className="list-unstyled components">
                            <li >
                                <a href="#" onClick={() => this.setState({ Page: "dashboard" })} data-toggle="collapse" aria-expanded="false" ><i className="fas fa-home"></i> Dashboard</a>
                            </li>

                            <li>
                                <a href="#shopSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                    <i className="fas fa-box-open"></i> Shop</a>
                                <ul className="collapse list-unstyled" id="shopSubmenu">
                                    <li>
                                        <a href="#" onClick={() => this.setState({ Page: "shop-orders" })}><i className="fas fa-list"></i> Orders</a>
                                    </li>

                                    <li>
                                        <a href="#" onClick={() => this.setState({ Page: "shop-customers" })} ><i className="fas fa-list"></i> Customers</a>
                                    </li>

                                    <li>
                                        <a href="#" onClick={() => this.setState({ Page: "shop-products" })} ><i className="fas fa-list"></i> Products</a>
                                    </li>

                                </ul>
                            </li>

                            <li>
                                <a href="#" onClick={() => this.setState({ Page: "customer" })}><i className="fas fa-briefcase"></i> Customers</a>
                                <a href="#orderSubMenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                    <i className="fas fa-copy"></i> Orders</a>
                                <ul className="collapse list-unstyled" id="orderSubMenu">
                                    <li>
                                        <a href="#" onClick={() => this.setState({ Page: "order-list" })}><i className="fas fa-list"></i> List</a>
                                    </li>

                                    <li>
                                        <a href="#" onClick={() => this.setState({ Page: "order-new" })} ><i className="fas fa-plus"></i> New</a>
                                    </li>

                                </ul>
                            </li>


                            <li>
                                <a href="#inventorySubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                    <i className="fas fa-copy"></i> Inventory</a>
                                <ul className="collapse list-unstyled" id="inventorySubmenu">
                                    <li>
                                        <a href="#" onClick={() => this.setState({ Page: "inventory-list" })}><i className="fas fa-list"></i> List</a>
                                    </li>

                                    <li>
                                        <a href="#" onClick={() => this.setState({ Page: "inventory-new" })} ><i className="fas fa-plus"></i> New</a>
                                    </li>

                                </ul>
                            </li>

                            <li>
                                <a href="#accountingSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                    <i className="fa fa-money-bill"></i> Accounting</a>
                                <ul className="collapse list-unstyled" id="accountingSubmenu">
                                    <li>
                                        <a href="#" onClick={() => this.setState({ Page: "accounting-list" })}><i className="fas fa-list"></i> List</a>
                                    </li>

                                    <li>
                                        <a href="#" onClick={() => this.setState({ Page: "accounting-new" })} ><i className="fas fa-plus"></i> New</a>
                                    </li>

                                </ul>
                            </li>
                            {/* <li>
                                <a href="#" onClick={() => this.setState({ Page: "accounting" })}><i className="fa fa-money-bill"></i> Accounting</a>
                            </li> */}

                            <li>
                                <a href="#"><i className="fas fa-question"></i> FAQ</a>
                            </li>
                            <li>
                                <a href="#"><i className="fas fa-paper-plane"></i> Help </a>
                            </li>
                        </ul>

                        <ul className="list-unstyled CTAs">
                            <li>
                                <a href="#" className="article">Version 1.0.0 , 2022</a>
                            </li>
                        </ul>
                    </nav>

                    <div id="content">

                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="container-fluid">

                                <button type="button" id="sidebarCollapse" onClick={() => this.collapseMenu()} className="btn btn-info">
                                    <i className="fas fa-align-left"></i>
                                    <span></span>
                                </button>
                                <button className="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <i className="fas fa-align-justify"></i>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="nav navbar-nav ml-auto">

                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Hi Y.M</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" onClick={() => this.logout()} href="#">Logout</a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </nav>

                        {this.setPage()}

                    </div>
                </div>
            );
        } else {
            return (
                <div className="mt-5">
                    <div style={{ width: '100%', height: '500px', textAlign: 'center' }}  >
                        <span >Loading...</span>
                    </div>
                </div>
            );
        }
    }

} export default MainPage;





