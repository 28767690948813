import React from 'react';
import { useLocation } from 'react-router-dom';

import Axios from 'axios';
import Swal from 'sweetalert2';


// import { DigitAnyToEn } from '../../utility/common.js';

class Facture2 extends React.Component {

    constructor(props) {
        super(props);
        console.log(props)

    }

    state = {
        fetching: false,
        order: null,
        order_product: null,
        products: null,
        customer: null,
        pages: 1,
        FFSafe: "0px",
        status: "b2b"
    }

    componentDidMount() {
        if (document.location && document.location.search.includes("oid")) {
            let params = (new URL(document.location)).searchParams;
            if (params.get("s") != null)
                document.title = "Order_" + params.get("s") + params.get("oid") + "_preview"
            else
                document.title = "Order" + params.get("oid") + "_preview"
            this.getOrder(params.get("oid"), params.get("s"), params.get("pre"))

        }
    }


    getPreOrder = (order) => {
        if (order === null) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                text: 'PreExport dosnt have any data',
                showConfirmButton: false,
                timer: 3500,
            });
            // document.window.close();
            return;
        }

        // OrderNew: {
        //     CustomerID: null,
        //     Customer: null,
        //     TotalNumber: null,
        //     TotalPrice: null,
        //     TotalQm: null,
        //     Currency: "EUR",
        //     Rabatt: 0,
        //     Zoll: 0,
        //     MwSt: 0,
        //     Description: "",
        //     Mode: 1,
        //     Products: null
        // }

        // this.state.customer = order.Customer


        document.getElementById("divCustomerInfo1").style.display = ''
        document.getElementById("divCustomerInfo2").style.display = ''
        const data = order.Products;
        var pageCount = 1
        var isFactorFooterSafe = "0px"
        if (data && data.length !== 0) {
            if (data.length > 37) pageCount = 2
            if (data.length > 86) pageCount = 3
            if (data.length > 135) pageCount = 4
            if (data.length > 184) pageCount = 5
            if (data.length > 233) pageCount = 6
            if (data.length > 282) pageCount = 7
            if (data.length > 331) pageCount = 8
            if (data.length > 380) pageCount = 9
            if (data.length > 429) pageCount = 10

            if (data.length < 38 && data.length > 27) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
            if (data.length < 87 && data.length > 76) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
            if (data.length < 136 && data.length > 125) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
            if (data.length < 185 && data.length > 174) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
            if (data.length < 234 && data.length > 223) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
            if (data.length < 283 && data.length > 272) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
            if (data.length < 332 && data.length > 321) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
            if (data.length < 381 && data.length > 370) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
            if (data.length < 430 && data.length > 419) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }

        }
        this.setState({ order: order, order_product: order.Products, customer: order.Customer, products: order.Products, pages: pageCount, FFSafe: isFactorFooterSafe });
        console.log(Response.data)


    }

    getOrder = (ID, status, IsPre) => {
        const Token = window.localStorage.getItem('MainLogInTOKEN');
        var context = this;
        if (status == "shop") {

            Axios.post('https://b2b.yborhani.de/api/shop/getOrder.php', { "ID": ID, "isPre": IsPre }, { headers: { 'Token': Token } }).then(Response => {
                if (Response.data.code === 0) {
                    document.getElementById("divCustomerInfo1").style.display = ''
                    document.getElementById("divCustomerInfo2").style.display = ''
                    const data = Response.data.products;
                    var pageCount = 1
                    var isFactorFooterSafe = "0px"
                    if (data && data.length !== 0) {
                        if (data.length > 37) pageCount = 2
                        if (data.length > 86) pageCount = 3
                        if (data.length > 135) pageCount = 4
                        if (data.length > 184) pageCount = 5
                        if (data.length > 233) pageCount = 6
                        if (data.length > 282) pageCount = 7
                        if (data.length > 331) pageCount = 8
                        if (data.length > 380) pageCount = 9
                        if (data.length > 429) pageCount = 10

                        if (data.length < 38 && data.length > 27) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                        if (data.length < 87 && data.length > 76) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                        if (data.length < 136 && data.length > 125) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                        if (data.length < 185 && data.length > 174) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                        if (data.length < 234 && data.length > 223) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                        if (data.length < 283 && data.length > 272) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                        if (data.length < 332 && data.length > 321) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                        if (data.length < 381 && data.length > 370) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                        if (data.length < 430 && data.length > 419) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }

                    }
                    context.setState({ status: "shop", order: Response.data.order, order_product: Response.data.order_product, customer: Response.data.customer, products: Response.data.products, pages: pageCount, FFSafe: isFactorFooterSafe });
                    console.log(Response.data)

                } else if (Response.data.code === 5) {
                    window.localStorage.setItem('MainLogInTOKEN', "")
                    window.location = "/login"
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        text: Response.data.message,
                        showConfirmButton: false,
                        timer: 3500,
                    });
                }
            })
            return
        }

        // delete customer from server

        Axios.post('https://b2b.yborhani.de/api/getOrder.php', { "ID": ID, "isPre": IsPre }, { headers: { 'Token': Token } }).then(Response => {
            if (Response.data.code === 0) {
                document.getElementById("divCustomerInfo1").style.display = ''
                document.getElementById("divCustomerInfo2").style.display = ''
                const data = Response.data.products;
                var pageCount = 1
                var isFactorFooterSafe = "0px"
                if (data && data.length !== 0) {
                    if (data.length > 37) pageCount = 2
                    if (data.length > 86) pageCount = 3
                    if (data.length > 135) pageCount = 4
                    if (data.length > 184) pageCount = 5
                    if (data.length > 233) pageCount = 6
                    if (data.length > 282) pageCount = 7
                    if (data.length > 331) pageCount = 8
                    if (data.length > 380) pageCount = 9
                    if (data.length > 429) pageCount = 10

                    if (data.length < 38 && data.length > 27) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                    if (data.length < 87 && data.length > 76) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                    if (data.length < 136 && data.length > 125) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                    if (data.length < 185 && data.length > 174) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                    if (data.length < 234 && data.length > 223) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                    if (data.length < 283 && data.length > 272) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                    if (data.length < 332 && data.length > 321) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                    if (data.length < 381 && data.length > 370) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }
                    if (data.length < 430 && data.length > 419) { isFactorFooterSafe = "440px"; pageCount = pageCount + 1 }

                }
                context.setState({ status: "b2b", order: Response.data.order, order_product: Response.data.order_product, customer: Response.data.customer, products: Response.data.products, pages: pageCount, FFSafe: isFactorFooterSafe });
                console.log(Response.data)

            } else if (Response.data.code === 5) {
                window.localStorage.setItem('MainLogInTOKEN', "")
                window.location = "/login"
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    text: Response.data.message,
                    showConfirmButton: false,
                    timer: 3500,
                });
            }
        })
    }

    ContentOrderProduct = () => {

        const data = this.state.products;

        if (data && data.length !== 0) { //
            if (this.state.status == "b2b")
                return (<div><table className="styled-table">
                    <thead>
                        <tr style={{ background: "GrayText" }}>
                            <th>&nbsp;&nbsp;Pos</th>
                            <th style={{ textAlign: 'center' }}>Art.Nr</th>
                            <th style={{ textAlign: 'center' }}>Bezeichnung</th>
                            <th style={{ textAlign: 'center' }}>Farbe</th>
                            <th style={{ textAlign: 'center' }}>Design</th>
                            <th style={{ textAlign: 'right' }}>Länge&nbsp;&times;&nbsp;Breite&nbsp;&nbsp;=&nbsp;</th>
                            <th style={{ textAlign: 'center' }}>Qm</th>
                            <th style={{ textAlign: 'center' }}>Anzahl</th>
                            <th style={{ textAlign: 'center' }}>P/Qm</th>
                            <th style={{ textAlign: 'center' }}>Preis</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => {
                            if (index == 37) {
                                return (<tr style={{ height: "140px" }} key={index}>
                                    <td style={{ paddingTop: "62px" }}>&nbsp;&nbsp;Pos <hr />&nbsp;&nbsp;{index + 1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Art.Nr<hr />{item.ItemNo1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Bezeichnung<hr />{item.Description}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Farbe<hr />&nbsp;{item.Colour}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Design<hr />&nbsp;{item.Design}</td>
                                    <td style={{ textAlign: 'right', paddingTop: "62px" }}>Länge&nbsp;&times;&nbsp;Breite&nbsp;&nbsp;=&nbsp;<hr />{item.Length}&nbsp;&times;&nbsp;{item.Width}&nbsp;&nbsp;=&nbsp;</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Qm<hr />{item.Qm}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Anzahl<hr />{this.state.order_product[index].Number}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>P/Qm<hr />{this.state.order_product[index].PricePerQm}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Preis<hr />{Math.round((item.Qm * this.state.order_product[index].Number * this.state.order_product[index].PricePerQm) * 100) / 100}</td>
                                </tr>)
                            } else if (index == 86) {
                                return (<tr style={{ height: "140px" }} key={index}>
                                    <td style={{ paddingTop: "62px" }}>&nbsp;&nbsp;Pos <hr />&nbsp;&nbsp;{index + 1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Art.Nr<hr />{item.ItemNo1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Bezeichnung<hr />{item.Description}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Farbe<hr />&nbsp;{item.Colour}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Design<hr />&nbsp;{item.Design}</td>
                                    <td style={{ textAlign: 'right', paddingTop: "62px" }}>Länge&nbsp;&times;&nbsp;Breite&nbsp;&nbsp;=&nbsp;<hr />{item.Length}&nbsp;&times;&nbsp;{item.Width}&nbsp;&nbsp;=&nbsp;</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Qm<hr />{item.Qm}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Anzahl<hr />{this.state.order_product[index].Number}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>P/Qm<hr />{this.state.order_product[index].PricePerQm}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Preis<hr />{Math.round((item.Qm * this.state.order_product[index].Number * this.state.order_product[index].PricePerQm) * 100) / 100}</td>
                                </tr>)
                            } else if (index == 135) {
                                return (<tr style={{ height: "140px" }} key={index}>
                                    <td style={{ paddingTop: "62px" }}>&nbsp;&nbsp;Pos <hr />&nbsp;&nbsp;{index + 1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Art.Nr<hr />{item.ItemNo1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Bezeichnung<hr />{item.Description}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Farbe<hr />&nbsp;{item.Colour}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Design<hr />&nbsp;{item.Design}</td>
                                    <td style={{ textAlign: 'right', paddingTop: "62px" }}>Länge&nbsp;&times;&nbsp;Breite&nbsp;&nbsp;=&nbsp;<hr />{item.Length}&nbsp;&times;&nbsp;{item.Width}&nbsp;&nbsp;=&nbsp;</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Qm<hr />{item.Qm}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Anzahl<hr />{this.state.order_product[index].Number}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>P/Qm<hr />{this.state.order_product[index].PricePerQm}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Preis<hr />{Math.round((item.Qm * this.state.order_product[index].Number * this.state.order_product[index].PricePerQm) * 100) / 100}</td>
                                </tr>)
                            } else if (index == 184) {
                                return (<tr style={{ height: "140px" }} key={index}>
                                    <td style={{ paddingTop: "62px" }}>&nbsp;&nbsp;Pos <hr />&nbsp;&nbsp;{index + 1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Art.Nr<hr />{item.ItemNo1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Bezeichnung<hr />{item.Description}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Farbe<hr />&nbsp;{item.Colour}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Design<hr />&nbsp;{item.Design}</td>
                                    <td style={{ textAlign: 'right', paddingTop: "62px" }}>Länge&nbsp;&times;&nbsp;Breite&nbsp;&nbsp;=&nbsp;<hr />{item.Length}&nbsp;&times;&nbsp;{item.Width}&nbsp;&nbsp;=&nbsp;</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Qm<hr />{item.Qm}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Anzahl<hr />{this.state.order_product[index].Number}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>P/Qm<hr />{this.state.order_product[index].PricePerQm}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Preis<hr />{Math.round((item.Qm * this.state.order_product[index].Number * this.state.order_product[index].PricePerQm) * 100) / 100}</td>
                                </tr>)
                            } else if (index == 233) {
                                return (<tr style={{ height: "140px" }} key={index}>
                                    <td style={{ paddingTop: "62px" }}>&nbsp;&nbsp;Pos <hr />&nbsp;&nbsp;{index + 1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Art.Nr<hr />{item.ItemNo1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Bezeichnung<hr />{item.Description}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Farbe<hr />&nbsp;{item.Colour}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Design<hr />&nbsp;{item.Design}</td>
                                    <td style={{ textAlign: 'right', paddingTop: "62px" }}>Länge&nbsp;&times;&nbsp;Breite&nbsp;&nbsp;=&nbsp;<hr />{item.Length}&nbsp;&times;&nbsp;{item.Width}&nbsp;&nbsp;=&nbsp;</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Qm<hr />{item.Qm}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Anzahl<hr />{this.state.order_product[index].Number}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>P/Qm<hr />{this.state.order_product[index].PricePerQm}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Preis<hr />{Math.round((item.Qm * this.state.order_product[index].Number * this.state.order_product[index].PricePerQm) * 100) / 100}</td>
                                </tr>)
                            } else
                                return (<tr key={index}>
                                    <td>&nbsp;&nbsp;{index + 1}</td>
                                    <td style={{ textAlign: 'center' }}>{item.ItemNo1}</td>
                                    <td style={{ textAlign: 'center' }}>{item.Description}</td>
                                    <td style={{ textAlign: 'center' }}>{item.Colour}</td>
                                    <td style={{ textAlign: 'center' }}>{item.Design}</td>
                                    <td style={{ textAlign: 'right' }}>{item.Length}&nbsp;&times;&nbsp;{item.Width}&nbsp;&nbsp;=&nbsp;</td>
                                    <td style={{ textAlign: 'center' }}>{item.Qm}</td>
                                    <td style={{ textAlign: 'center' }}>{this.state.order_product[index].Number}</td>
                                    <td style={{ textAlign: 'center' }}>{this.state.order_product[index].PricePerQm}</td>
                                    <td style={{ textAlign: 'center' }}>{Math.round((item.Qm * this.state.order_product[index].Number * this.state.order_product[index].PricePerQm) * 100) / 100}</td>
                                </tr>)
                        })}
                    </tbody>
                </table >
                    <div class="form-row" style={{ paddingTop: this.state.FFSafe }}>
                        <div class="form-group col-md-3">
                            <div id="divTotalNumber" className="mt-2 text-left" style={{ textAlign: "center" }}>Total Number&nbsp;:&nbsp; {this.state.order.TotalNumber}</div>
                        </div>
                        <div class="form-group col-md-3">
                            <div id="divTotalQm" className="mt-2" style={{ textAlign: "center" }}>Total Qm&nbsp;:&nbsp;{this.state.order.TotalQm} </div>
                        </div>
                        <div class="form-group col-md-3"></div>
                        <div class="form-group col-md-3">
                            {/* <div id="divTotalPrice" className="mt-2 text-right" style={{ textAlign: "right", fontWeight: "bold" }}>Total Price&nbsp;:&nbsp;{this.state.order.TotalPrice}&nbsp;&nbsp;&nbsp;{this.state.order.Currency}</div> */}
                            <div className="row text-right">
                                <div className="col-6 text-left">Summe</div>
                                <div className="col-2">:{this.state.order.Currency}</div>
                                <div className="col-4">{this.state.order.TotalPrice}</div>

                                {/* <div className="col-6 text-left">Rabatt {this.state.order.Rabatt}%</div>
                            <div className="col-2">:{this.state.order.Currency}</div>
                            <div className="col-4">-{Math.round(this.state.order.TotalPrice * this.state.order.Rabatt) / 100}</div> */}
                                {this.setRabatt1()}
                                {this.setRabatt2()}
                                {this.setRabatt3()}

                                {/* <div className="col-6 text-left">Zoll {this.state.order.Zoll}%</div>
                            <div className="col-2">:{this.state.order.Currency}</div>
                            <div className="col-4">{Math.round(this.state.order.TotalPrice * this.state.order.Zoll) / 100}</div> */}
                                {this.setZoll1()}
                                {this.setZoll2()}
                                {this.setZoll3()}

                                {/* <div className="col-6 text-left">MwSt {this.state.order.MwSt}%</div>
                            <div className="col-2">:{this.state.order.Currency}</div>
                            <div className="col-4">{Math.round(this.state.order.TotalPrice * this.state.order.MwSt) / 100}</div> */}
                                {this.setMwSt1()}
                                {this.setMwSt2()}
                                {this.setMwSt3()}

                                <div className="col-12" style={{ backgroundColor: 'black', width: '100%', height: '1px', WebkitPrintColorAdjust: 'exact' }} />
                                <div className="col-6 text-left"><b>Total</b></div>
                                <div className="col-2"><b>:{this.state.order.Currency}</b></div>
                                <div className="col-4"><b>{Math.round((Number(this.state.order.Zoll) + Number(this.state.order.TotalPrice) + Number(Math.round(this.state.order.TotalPrice * this.state.order.MwSt) / 100) - Number(Math.round(this.state.order.TotalPrice * this.state.order.Rabatt) / 100)) * 100) / 100}</b></div>
                                <div className="col-12" style={{ backgroundColor: 'black', width: '100%', height: '2px', WebkitPrintColorAdjust: 'exact' }} />
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-2">
                        <div class="form-group col-md-12">
                            <div id="divTotalNumber" className="mt-2 text-left" style={{ textAlign: "center" }}>Description: {this.state.order.Description} </div>
                        </div>
                    </div>

                </div >)
            else
                return (<div><table className="styled-table">
                    <thead>
                        <tr style={{ background: "GrayText" }}>
                            <th>&nbsp;&nbsp;Pos</th>
                            <th style={{ textAlign: 'center' }}>Art.Nr</th>
                            <th style={{ textAlign: 'center' }}>Bezeichnung</th>
                            <th style={{ textAlign: 'center' }}>Farbe</th>
                            <th style={{ textAlign: 'center' }}>Design</th>
                            <th style={{ textAlign: 'right' }}>Länge&nbsp;&times;&nbsp;Breite&nbsp;&nbsp;=&nbsp;</th>
                            <th style={{ textAlign: 'center' }}>Qm</th>
                            <th style={{ textAlign: 'center' }}>Anzahl</th>
                            <th style={{ textAlign: 'center' }}>P/Qm</th>
                            <th style={{ textAlign: 'center' }}>Preis</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => {
                            if (index == 37) {
                                return (<tr style={{ height: "140px" }} key={index}>
                                    <td style={{ paddingTop: "62px" }}>&nbsp;&nbsp;Pos <hr />&nbsp;&nbsp;{index + 1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Art.Nr<hr />{item.model}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Bezeichnung<hr />{this.state.order_product[index].name}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Farbe<hr />&nbsp;--</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Design<hr />&nbsp;--</td>
                                    <td style={{ textAlign: 'right', paddingTop: "62px" }}>Länge&nbsp;&times;&nbsp;Breite&nbsp;&nbsp;=&nbsp;<hr />{Math.floor(item.length)}&nbsp;&times;&nbsp;{Math.floor(item.width)}&nbsp;&nbsp;=&nbsp;</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Qm<hr />{(item.length * item.width) / 10000}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Anzahl<hr />{this.state.order_product[index].quantity}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>P/Qm<hr />--</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Preis<hr />{this.state.order_product[index].total}</td>
                                </tr>)
                            } else if (index == 86) {
                                return (<tr style={{ height: "140px" }} key={index}>
                                    <td style={{ paddingTop: "62px" }}>&nbsp;&nbsp;Pos <hr />&nbsp;&nbsp;{index + 1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Art.Nr<hr />{this.state.order_product[index].model}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Bezeichnung<hr />{item.name}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Farbe<hr />&nbsp;--</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Design<hr />&nbsp;--</td>
                                    <td style={{ textAlign: 'right', paddingTop: "62px" }}>Länge&nbsp;&times;&nbsp;Breite&nbsp;&nbsp;=&nbsp;<hr />{Math.floor(item.length)}&nbsp;&times;&nbsp;{Math.floor(item.width)}&nbsp;&nbsp;=&nbsp;</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Qm<hr />{(item.length * item.width) / 10000}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Anzahl<hr />{this.state.order_product[index].quantity}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>P/Qm<hr />--</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Preis<hr />{this.state.order_product[index].total}</td>
                                </tr>)
                            } else if (index == 135) {
                                return (<tr style={{ height: "140px" }} key={index}>
                                    <td style={{ paddingTop: "62px" }}>&nbsp;&nbsp;Pos <hr />&nbsp;&nbsp;{index + 1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Art.Nr<hr />{this.state.order_product[index].model}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Bezeichnung<hr />{item.name}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Farbe<hr />&nbsp;--</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Design<hr />&nbsp;--</td>
                                    <td style={{ textAlign: 'right', paddingTop: "62px" }}>Länge&nbsp;&times;&nbsp;Breite&nbsp;&nbsp;=&nbsp;<hr />{Math.floor(item.length)}&nbsp;&times;&nbsp;{Math.floor(item.width)}&nbsp;&nbsp;=&nbsp;</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Qm<hr />{(item.length * item.width) / 10000}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Anzahl<hr />{this.state.order_product[index].quantity}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>P/Qm<hr />--</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Preis<hr />{this.state.order_product[index].total}</td>
                                </tr>)
                            } else if (index == 184) {
                                return (<tr style={{ height: "140px" }} key={index}>
                                    <td style={{ paddingTop: "62px" }}>&nbsp;&nbsp;Pos <hr />&nbsp;&nbsp;{index + 1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Art.Nr<hr />{item.model}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Bezeichnung<hr />{this.state.order_product[index].name}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Farbe<hr />&nbsp;--</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Design<hr />&nbsp;--</td>
                                    <td style={{ textAlign: 'right', paddingTop: "62px" }}>Länge&nbsp;&times;&nbsp;Breite&nbsp;&nbsp;=&nbsp;<hr />{Math.floor(item.length)}&nbsp;&times;&nbsp;{Math.floor(item.width)}&nbsp;&nbsp;=&nbsp;</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Qm<hr />{(item.length * item.width) / 10000}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Anzahl<hr />{this.state.order_product[index].quantity}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>P/Qm<hr />--</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Preis<hr />{this.state.order_product[index].total}</td>
                                </tr>)
                            } else if (index == 233) {
                                return (<tr style={{ height: "140px" }} key={index}>
                                    <td style={{ paddingTop: "62px" }}>&nbsp;&nbsp;Pos <hr />&nbsp;&nbsp;{index + 1}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Art.Nr<hr />{item.model}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Bezeichnung<hr />{this.state.order_product[index].name}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Farbe<hr />&nbsp;--</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Design<hr />&nbsp;--</td>
                                    <td style={{ textAlign: 'right', paddingTop: "62px" }}>Länge&nbsp;&times;&nbsp;Breite&nbsp;&nbsp;=&nbsp;<hr />{Math.floor(item.length)}&nbsp;&times;&nbsp;{Math.floor(item.width)}&nbsp;&nbsp;=&nbsp;</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Qm<hr />{(item.length * item.width) / 10000}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Anzahl<hr />{this.state.order_product[index].quantity}</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>P/Qm<hr />--</td>
                                    <td style={{ textAlign: 'center', paddingTop: "62px" }}>Preis<hr />{this.state.order_product[index].total}</td>
                                </tr>)
                            } else
                                return (<tr key={index}>
                                    <td>&nbsp;&nbsp;{index + 1}</td>
                                    <td style={{ textAlign: 'center' }}>{item.model}</td>
                                    <td style={{ textAlign: 'center' }}>{this.state.order_product[index].name}</td>
                                    <td style={{ textAlign: 'center' }}>&nbsp;--</td>
                                    <td style={{ textAlign: 'center' }}>&nbsp;--</td>
                                    <td style={{ textAlign: 'right' }}>{Math.floor(item.length)}&nbsp;&times;&nbsp;{Math.floor(item.width)}&nbsp;&nbsp;=&nbsp;</td>
                                    <td style={{ textAlign: 'center' }}>{(item.length * item.width) / 10000}</td>
                                    <td style={{ textAlign: 'center' }}>{this.state.order_product[index].quantity}</td>
                                    <td style={{ textAlign: 'center' }}>{(Math.floor((this.state.order_product[index].total / ((item.length * item.width) / 10000)) * 100)) / 100}</td>
                                    <td style={{ textAlign: 'center' }}>{this.state.order_product[index].total}</td>
                                </tr>)
                        })}
                    </tbody>
                </table >
                    <div class="form-row" style={{ paddingTop: this.state.FFSafe }}>
                        <div class="form-group col-md-3">
                            <div id="divTotalNumber" className="mt-2 text-left" style={{ textAlign: "center" }}>Total Number&nbsp;:&nbsp; {this.state.products.length}</div>
                        </div>
                        <div class="form-group col-md-3">
                            <div id="divTotalQm" className="mt-2" style={{ textAlign: "center" }}>Total Qm&nbsp;:&nbsp;-- </div>
                        </div>
                        <div class="form-group col-md-3"></div>
                        <div class="form-group col-md-3">
                            {/* <div id="divTotalPrice" className="mt-2 text-right" style={{ textAlign: "right", fontWeight: "bold" }}>Total Price&nbsp;:&nbsp;{this.state.order.TotalPrice}&nbsp;&nbsp;&nbsp;{this.state.order.Currency}</div> */}
                            <div className="row text-right">
                                <div className="col-6 text-left">Summe</div>
                                <div className="col-2">:{this.state.order.currency_code}</div>
                                <div className="col-4">{this.state.order.total}</div>

                                {/* <div className="col-6 text-left">Rabatt {this.state.order.Rabatt}%</div>
             <div className="col-2">:{this.state.order.Currency}</div>
             <div className="col-4">-{Math.round(this.state.order.TotalPrice * this.state.order.Rabatt) / 100}</div> */}
                                {this.setRabatt1()}
                                {this.setRabatt2()}
                                {this.setRabatt3()}

                                {/* <div className="col-6 text-left">Zoll {this.state.order.Zoll}%</div>
             <div className="col-2">:{this.state.order.Currency}</div>
             <div className="col-4">{Math.round(this.state.order.TotalPrice * this.state.order.Zoll) / 100}</div> */}
                                {this.setZoll1()}
                                {this.setZoll2()}
                                {this.setZoll3()}

                                {/* <div className="col-6 text-left">MwSt {this.state.order.MwSt}%</div>
             <div className="col-2">:{this.state.order.Currency}</div>
             <div className="col-4">{Math.round(this.state.order.TotalPrice * this.state.order.MwSt) / 100}</div> */}
                                {this.setMwSt1()}
                                {this.setMwSt2()}
                                {this.setMwSt3()}

                                <div className="col-12" style={{ backgroundColor: 'black', width: '100%', height: '1px', WebkitPrintColorAdjust: 'exact' }} />
                                <div className="col-6 text-left"><b>Total</b></div>
                                <div className="col-2"><b>:{this.state.order.currency_code}</b></div>
                                <div className="col-4"><b>{this.state.order.total}</b></div>
                                <div className="col-12" style={{ backgroundColor: 'black', width: '100%', height: '2px', WebkitPrintColorAdjust: 'exact' }} />
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-2">
                        <div class="form-group col-md-12">
                            <div id="divTotalNumber" className="mt-2 text-left" style={{ textAlign: "center" }}>Description: {this.state.order.comment} </div>
                        </div>
                    </div>

                </div >)

        } else {
            return (<div style={{ width: '100%', textAlign: 'center', height: '90px', margin: '20px 0' }}>
                <span style={{ color: 'gray', fontSize: '18px', display: 'block' }}>Loading ...</span>
            </div>)
        }
    }

    setRabatt1 = () => {
        if (this.state.status == "b2b")
            if (this.state.order.Rabatt > 0) return (<div className="col-6 text-left">Rabatt {this.state.order.Rabatt}%</div>)
    }
    setRabatt2 = () => {
        if (this.state.status == "b2b")
            if (this.state.order.Rabatt > 0) return (<div className="col-2">:{this.state.order.Currency}</div>)
    }
    setRabatt3 = () => {
        if (this.state.status == "b2b")
            if (this.state.order.Rabatt > 0) return (<div className="col-4">-{Math.round(this.state.order.TotalPrice * this.state.order.Rabatt) / 100}</div>)
    }

    setZoll1 = () => {
        if (this.state.status == "b2b")
            if (this.state.order.Zoll > 0) return (<div className="col-6 text-left">Zoll</div>)
    }
    setZoll2 = () => {
        if (this.state.status == "b2b")
            if (this.state.order.Zoll > 0) return (<div className="col-2">:{this.state.order.Currency}</div>)
    }
    setZoll3 = () => {
        if (this.state.status == "b2b")
            if (this.state.order.Zoll > 0) return (<div className="col-4">{this.state.order.Zoll}</div>)
    }

    setMwSt1 = () => {
        if (this.state.status == "b2b")
            if (this.state.order.MwSt > 0) return (<div className="col-6 text-left">MwSt {this.state.order.MwSt}%</div>)
    }
    setMwSt2 = () => {
        if (this.state.status == "b2b")
            if (this.state.order.MwSt > 0) return (<div className="col-2">:{this.state.order.Currency}</div>)
    }
    setMwSt3 = () => {
        if (this.state.status == "b2b")
            if (this.state.order.MwSt > 0) return (<div className="col-4">{Math.round(this.state.order.TotalPrice * this.state.order.MwSt) / 100}</div>)
    }

    setEuroDate = () => {
        var cdt = "00-00-00 00:00"
        if (this.state.status == "shop")
            cdt = (this.state.order) ? (this.state.order.date_added) : "00-00-00 00:00"
        else
            cdt = (this.state.order) ? (this.state.order.Createdatetime) : "00-00-00 00:00"

        cdt = cdt.split(' ')[0]
        var date = cdt.split('-')[2] + "." +
            cdt.split('-')[1] + "." +
            cdt.split('-')[0]
        return (<div>Date: {date}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page(s): {this.state.pages}</div >)
    }

    render() {
        if (this.state.status == "b2b")
            return (
                <div style={{ background: "#fff" }}>

                    <div class="container" style={{ marginTop: "110px", marginBottom: '110px' }}>
                        <div class="row" id="divCustomerInfo1" style={{ display: "none" }}>
                            <div class="col-6 mt-3">
                                <div className="mt-5">
                                    Y. Borhani GmbH . Brook 7 . D-20457 Hamburg
                            </div>
                                <div style={{ backgroundColor: '#333', width: '100%', height: '1px', marginBottom: '10px', WebkitPrintColorAdjust: 'exact' }} />
                                <h5 className="mt-5">
                                    {(this.state.customer) ? (this.state.customer.Name1) : "---"}
                                    <br />
                                    {(this.state.customer) ? (this.state.customer.Name2) : "---"}
                                    <br />
                                    {(this.state.customer) ? (this.state.customer.Strasse) : "---"}
                                    <br />
                                    {(this.state.customer) ? (this.state.customer.Stadt) : "---"}
                                </h5>
                            </div>
                            <div class="col-2">

                                Büro & Lager:
                            <br />
                                Y. Borhani GmbH
                            <br />
                                Brook 7
                            <br />
                                20457 Hamburg
                            <br />
                                Tel: 040-373142
                            <br />
                                Fax: 040-373102

                        </div>
                            <div class="col-4 pl-5">

                                Ust.Id Nr: DE167893835
                            <br />
                                Zoll Nr: 3622681
                            <br />
                                Ozl Nr: DE/CWP/4600LC001222
                            <br />
                                Email: info@yborhani.de
                            <br />
                                web: www.yborhani.de
                            <br />
                                <br />
                                <br />
                                <b>Rechnung Nr: {new Date().getFullYear()}{(this.state.order) ? (this.state.order.ID) : "0"}</b>
                                <br />
                                Kunden Nr: {(this.state.customer) ? (this.state.customer.Nummer) : "0"}
                                <br />
                                Auftrag Nr: {(this.state.order) ? (this.state.order.ID) : "0"}
                            </div>

                            <div className="col-6 mt-4">
                                Tax ID Custommer Nr: -----

                        </div>
                            <div className="col-6 mt-4 text-right">
                                {this.setEuroDate()}

                                {/* Date: {(this.state.order) ? (this.state.order.Createdatetime) : "00-00-00"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page: 1 */}
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-12" style={{ marginBottom: "140px" }}>
                                <div className="rounded pt-2">
                                    {(!this.state.fetching) ? (this.ContentOrderProduct()) : (<div style={{ textAlign: 'center', margin: '10px 0' }}>
                                        <div className="spinner-border text-warning" style={{ width: '90px', height: '90px' }} role="status">
                                            <span className="sr-only" ></span>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="printheader pl-5 pr-5 pt-3">

                        <div class="row mb-0 pb-0" id="divCustomerInfo2" style={{ display: "none" }}>
                            <div class="col-8 pt-0 pb-0 mb-0">
                                <h3 className="text-left mt-5 mb-0 pb-0">Y. Borhani GmbH</h3>
                            </div>
                            <div class="col-4 text-right">
                                <img style={{ width: '100px', height: '40px', marginTop: '37px' }} alt="GmbH" src="./logo.png" />
                            </div>
                            {/* <h5 class="col-4 text-right">
                            Datum:  {(this.state.order) ? (this.state.order.Createdatetime) : "---"}
                            <br />
                            Seite: 1
                        </h5> */}

                        </div>
                        {/* <hr /> */}
                        <div style={{ backgroundColor: 'black', width: '100%', height: '3px', WebkitPrintColorAdjust: 'exact' }} />

                        {/* <hr className="pr-5" style={{ position: 'fixed', marginTop: '0px', color: '#333', backgroundColor: '#333', width: '100%', height: "3px" }} /> */}

                    </div>
                    <div className="printfooter">

                        <div className="row">
                            <div className="col-3 pr-0 pl-0 font-weight-bold" style={{ fontSize: '14px' }}>Bank: M. M. Warburg Bank</div>
                            <div className="col-3 pr-0 pl-0 font-weight-bold" style={{ fontSize: '14px' }}>IBAN EUR: DE31 2012 0100 1000 2851 53</div>
                            <div className="col-2 pr-0 pl-0 font-weight-bold" style={{ fontSize: '14px' }}>SWIFT-BIC: WBWCDEHH</div>
                            <div className="col-4 pr-0 pl-0 font-weight-bold" style={{ fontSize: '14px' }}>IBAN USD: DE72 2012 0100 1005 2851 53</div>

                        </div>





                        <div className="text-left my-3 pl-2 pr-2 pb-1" >
                            <div className="border p-1" style={{ fontSize: '14px' }}>
                                Der Lieferant erklärt, daß die in dieser Rechnung aufgeführten Teppiche nach den in der EG geltenden Regelung ihren Ursprung in o.g. Ländern haben. Wir liefern grundsätzlich nur nach Maßgabe unserer umseitigen Bedingungen.
                        </div>
                            <div className="" style={{ fontSize: '14px' }}>Geschäftsführer: Yosef Borhani&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HRB 55282</div>
                        </div>
                    </div>
                </div>
            );
        else
            return (
                <div style={{ background: "#fff" }}>

                    <div class="container" style={{ marginTop: "110px", marginBottom: '110px' }}>
                        <div class="row" id="divCustomerInfo1" style={{ display: "none" }}>
                            <div class="col-6 mt-3">
                                <div className="mt-5">
                                    Y. Borhani GmbH . Brook 7 . D-20457 Hamburg
                        </div>
                                <div style={{ backgroundColor: '#333', width: '100%', height: '1px', marginBottom: '10px', WebkitPrintColorAdjust: 'exact' }} />
                                <h5 className="mt-5">
                                    {(this.state.customer) ? (this.state.customer.firstname) : "---"}
                                    <br />
                                    {(this.state.customer) ? (this.state.customer.lastname) : "---"}
                                    <br />
                                    {(this.state.order) ? (this.state.order.shipping_address_1) : "---"}
                                    <br />
                                    {(this.state.order) ? (this.state.order.shipping_country) : "---"}
                                </h5>
                            </div>
                            <div class="col-2">

                                Büro & Lager:
                        <br />
                                Y. Borhani GmbH
                        <br />
                                Brook 7
                        <br />
                                20457 Hamburg
                        <br />
                                Tel: 040-373142
                        <br />
                                Fax: 040-373102

                    </div>
                            <div class="col-4 pl-5">

                                Ust.Id Nr: DE167893835
                        <br />
                                Zoll Nr: 3622681
                        <br />
                                Ozl Nr: DE/CWP/4600LC001222
                        <br />
                                Email: info@yborhani.de
                        <br />
                                web: www.yborhani.de
                        <br />
                                <br />
                                <br />
                                <b>Rechnung Nr: {new Date().getFullYear()}{(this.state.order) ? (this.state.order.order_id) : "0"}</b>
                                <br />
                                Kunden Nr: {(this.state.customer) ? (this.state.customer.customer_id) : "0"}
                                <br />
                                Auftrag Nr: {(this.state.order) ? (this.state.order.order_id) : "0"}
                            </div>

                            <div className="col-6 mt-4">
                                Tax ID Custommer Nr: -----

                    </div>
                            <div className="col-6 mt-4 text-right">
                                {this.setEuroDate()}

                                {/* Date: {(this.state.order) ? (this.state.order.Createdatetime) : "00-00-00"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page: 1 */}
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-12" style={{ marginBottom: "140px" }}>
                                <div className="rounded pt-2">
                                    {(!this.state.fetching) ? (this.ContentOrderProduct()) : (<div style={{ textAlign: 'center', margin: '10px 0' }}>
                                        <div className="spinner-border text-warning" style={{ width: '90px', height: '90px' }} role="status">
                                            <span className="sr-only" ></span>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="printheader pl-5 pr-5 pt-3">

                        <div class="row mb-0 pb-0" id="divCustomerInfo2" style={{ display: "none" }}>
                            <div class="col-8 pt-0 pb-0 mb-0">
                                <h3 className="text-left mt-5 mb-0 pb-0">Y. Borhani GmbH</h3>
                            </div>
                            <div class="col-4 text-right">
                                <img style={{ width: '100px', height: '40px', marginTop: '37px' }} alt="GmbH" src="./logo.png" />
                            </div>
                            {/* <h5 class="col-4 text-right">
                        Datum:  {(this.state.order) ? (this.state.order.Createdatetime) : "---"}
                        <br />
                        Seite: 1
                    </h5> */}

                        </div>
                        {/* <hr /> */}
                        <div style={{ backgroundColor: 'black', width: '100%', height: '3px', WebkitPrintColorAdjust: 'exact' }} />

                        {/* <hr className="pr-5" style={{ position: 'fixed', marginTop: '0px', color: '#333', backgroundColor: '#333', width: '100%', height: "3px" }} /> */}

                    </div>
                    <div className="printfooter">

                        <div className="row">
                            <div className="col-3 pr-0 pl-0 font-weight-bold" style={{ fontSize: '14px' }}>Bank: M. M. Warburg Bank</div>
                            <div className="col-3 pr-0 pl-0 font-weight-bold" style={{ fontSize: '14px' }}>IBAN EUR: DE31 2012 0100 1000 2851 53</div>
                            <div className="col-2 pr-0 pl-0 font-weight-bold" style={{ fontSize: '14px' }}>SWIFT-BIC: WBWCDEHH</div>
                            <div className="col-4 pr-0 pl-0 font-weight-bold" style={{ fontSize: '14px' }}>IBAN USD: DE72 2012 0100 1005 2851 53</div>

                        </div>





                        <div className="text-left my-3 pl-2 pr-2 pb-1" >
                            <div className="border p-1" style={{ fontSize: '14px' }}>
                                Der Lieferant erklärt, daß die in dieser Rechnung aufgeführten Teppiche nach den in der EG geltenden Regelung ihren Ursprung in o.g. Ländern haben. Wir liefern grundsätzlich nur nach Maßgabe unserer umseitigen Bedingungen.
                    </div>
                            <div className="" style={{ fontSize: '14px' }}>Geschäftsführer: Yosef Borhani&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HRB 55282</div>
                        </div>
                    </div>
                </div>
            );
    }
}

export default Facture2;