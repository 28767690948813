import React, { Component } from 'react';
// import { jalaliDate } from '../utility/Date';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { right } from '@popperjs/core';

class InventoryNew extends Component {

    state = {
        fetching: false,
        searchPhoneNO: null,
        searchFullName: null,
        userlist: null,
        previewuserdata: null,
        selectedIndex: null,
        productlist: null,
        selectedproductlist: null,
        haveCustomer: false,
        haveProducts: false,
        totalorderprice: 0,
        OrderNew: {
            CustomerID: null,
            TotalNumber: null,
            TotalPrice: null,
            TotalQm: null,
            Currency: "EUR",
            Products: null
        }
    }


    Date = (date) => {
        const year = date.slice(0, 4);
        const month = date.slice(5, 7);
        const day = date.slice(8, 10);
    }

    EnToPerNumFunc = (input) => {
        let inpNum = input.toString();
        const perNums = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
        const EnNumbersRegExp = [/0/g, /1/g, /2/g, /3/g, /4/g, /5/g, /6/g, /7/g, /8/g, /9/g];
        for (let i = 0; i < 10; i++) {
            inpNum = inpNum.replace(EnNumbersRegExp[i], perNums[i]);
        };
        return inpNum;
    }

    UserStatusFunc = (UserStatusID) => {
        switch (UserStatusID) {
            case 1: {
                return (<span style={{ color: 'green' }}>فعال</span>);
            }
            case 2: {
                return (<span style={{ color: 'red' }}>غیرفعال</span>);
            }
            case 3: {
                return (<span style={{ color: '#fe9c01' }}>در انتظار بررسی</span>);
            }
            case 4: {
                return (<span style={{ color: 'red', fontSize: '14px' }}>رد شده به علت<br />نقص اطلاعات</span>);
            }
            case 5: {
                return (<span style={{ color: 'red', fontSize: '14px' }}>رد شده به علت عدم<br />احراز صحت اطلاعات</span>);
            }
        }
    }

    UserDataPrevFunc = (item, index, mode) => {

        switch (mode) {
            case "import-customer":
                this.setState({ previewuserdata: item, selectedIndex: index, haveCustomer: true });
                document.getElementById("divCusomerInfo").style.display = ''
                document.getElementById("divCusomerEmpty").style.display = 'none'
                document.getElementById("viewName1").value = item.Name1
                document.getElementById("viewName2").value = item.Name2
                document.getElementById("viewStadt").value = item.Stadt
                document.getElementById("viewStrasse").value = item.Strasse
                document.getElementById("viewPLZ").value = item.PLZ
                document.getElementById("viewLand").value = item.Land
                document.getElementById("viewTel1").value = item.Tel1
                document.getElementById("viewTel2").value = item.Tel2
                document.getElementById("viewFax").value = item.Fax
                document.getElementById("viewWWW").value = item.WWW
                document.getElementById("viewTaxID").value = item.TaxID
                document.getElementById("viewEmail").value = item.Email
                document.getElementById("divCustomerID").innerHTML = "Customer ID: " + item.ID
                document.getElementById("divCustomerNummer").innerHTML = "Customer Nummer: " + item.Nummer
                this.state.OrderNew.CustomerID = item.ID;
                break;

            case "import-product":
                // console.log(item);
                var myArray = [];
                if (this.state.selectedproductlist !== null) {
                    myArray = this.state.selectedproductlist
                }

                const found = myArray.find(obj => {
                    return obj.ID === item.ID;
                });

                console.log(found);

                // if (found) return;

                if (found !== undefined) return;

                myArray.push(item);
                this.setState({ selectedproductlist: myArray, selectedIndex: index, haveProducts: true });

                break;

            case "remove-product-from-list":
                try {
                    this.state.selectedproductlist = this.state.selectedproductlist.filter((itm) => itm.ID !== item.ID)
                    this.setState({ selectedproductlist: this.state.selectedproductlist });

                    this.SumTotlaPrice_RefreshCalculator(index)
                } catch (error) {
                    console.log(error)
                }
                break;

            default:
                break;
        }
        // document.getElementById("AdUserListSituationSelectID").selectedIndex = data.UserStatusID - 1;
    }

    UserDataEditFunc = () => {
        const token = window.sessionStorage.getItem('SatCPToken');
        const username = this.state.previewuserdata.Username;
        const statusID = null //document.getElementById("AdUserListSituationSelectID").value;
        const searchPhoneNO = this.state.searchPhoneNO;
        const searchFullName = this.state.searchFullName;

        Axios.post('http://ms.esay.ir/user/updateAdUser.php', { "Username": username, "StatusID": statusID }, { headers: { Token: token } }).then(Response => {
            if (Response.data.code === 0) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    text: Response.data.messsage,
                    showConfirmButton: false,
                    timer: 3500,
                });

                this.setState({ fetching: true });
                Axios.post('http://ms.esay.ir/user/getAdUserList.php', { "phone": searchPhoneNO, "fullname": searchFullName }, { headers: { Token: token } }).then(Response => {
                    if (Response.data.code === 0) {
                        this.setState({ fetching: false, userlist: Response.data.userlist })
                    }
                })
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    text: Response.data.message,
                    showConfirmButton: false,
                    timer: 3500,
                });
            }
        })
    }

    SearchFunc = () => {

        const CustomerNummer = document.getElementById("searchCustomerNummer").value.trim();
        const CustomerID = document.getElementById("searchCustomerID").value.trim();
        const CustomerName = document.getElementById("searchCustomerName").value.trim();
        const Token = window.localStorage.getItem('MainLogInTOKEN');
        // console.log(email)
        if (CustomerNummer !== "" || CustomerID !== "" || CustomerName !== "") {
            // console.log(Token)
            // this.setState({ fetching: true, searchPhoneNO: email, searchFullName: name });
            Axios.post('https://b2b.yborhani.de/api/getCustomers.php', { "CustomerNummer": CustomerNummer, "CustomerID": CustomerID, "CustomerName": CustomerName }, { headers: { 'Token': Token } }).then(Response => {
                // console.log(Response.data)
                if (Response.data.code === 0) {
                    this.setState({ fetching: false, userlist: Response.data.customers })
                } else if (Response.data.code === 5) {
                    window.localStorage.setItem('MainLogInTOKEN', "")
                    window.location = "/login"
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        text: Response.data.message,
                        showConfirmButton: false,
                        timer: 3500,
                    });
                }
            })
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                text: 'Please enter somethings.',
                showConfirmButton: false,
                timer: 3500,
            });
            // window.focus();
            // document.getElementById("btnSearch").current.blur();
        }
    }

    SearchProductFunc = () => {
        // InventoryID
        // InventoryStockNo
        // InventoryDescription

        const InventoryID = document.getElementById("searchInventoryID").value.trim();
        const InventoryStockNo = document.getElementById("searchInventoryStockNo").value.trim();
        const InventoryDescription = document.getElementById("searchInventoryDescription").value.trim();


        const Token = window.localStorage.getItem('MainLogInTOKEN');
        // console.log(email)
        if (InventoryID !== "" || InventoryStockNo !== "" || InventoryDescription !== "") {
            // console.log(Token)
            this.setState({ fetching: true });
            Axios.post('https://b2b.yborhani.de/api/getInventory.php', { "InventoryID": InventoryID, "InventoryStockNo": InventoryStockNo, "InventoryDescription": InventoryDescription }, { headers: { 'Token': Token } }).then(Response => {
                // console.log(Response.data)
                if (Response.data.code === 0) {
                    this.setState({ fetching: false, productlist: Response.data.inventory })
                } else if (Response.data.code === 5) {
                    window.localStorage.setItem('MainLogInTOKEN', "")
                    window.location = "/login"
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        text: Response.data.message,
                        showConfirmButton: false,
                        timer: 3500,
                    });
                }
            })
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                text: 'Please enter somethings.',
                showConfirmButton: false,
                timer: 3500,
            });
        }
    }

    ContentFunc = () => {
        const data = this.state.userlist;

        if (data && data.length !== 0) { //
            return (<table className="styled-table">
                <thead>
                    <tr>
                        <th>&nbsp;&nbsp;ID</th>
                        <th>Name1</th>
                        <th>Stadt</th>
                        <th>Tel1</th>
                        <th style={{ width: '80px', textAlign: 'center' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                        // if (index % 2 == 0) {
                        return (<tr key={index}>
                            <td>&nbsp;&nbsp;{item.Nummer}</td>
                            <td>{(item.Name1 === "" ? "-" : item.Name1)}</td>
                            <td>{item.Stadt}</td>
                            <td>{item.Tel1}</td>
                            <td style={{ width: '80px', textAlign: 'center' }}>
                                <button type="button" className="btn btn-link" data-dismiss="modal" onClick={() => this.UserDataPrevFunc(item, index, "import-customer")} style={{ width: '40px', color: '#fe9c01' }}><i className="fas fa-plus"></i></button>
                            </td>
                        </tr>)
                    })}
                </tbody>
            </table >)
        } else {
            return (<div style={{ width: '100%', textAlign: 'center', height: '90px', margin: '20px 0' }}>
                <span style={{ color: 'gray', fontSize: '18px', display: 'block' }}>Record not found.</span>
            </div>)
        }
    }

    ContentProduct = () => {
        const data = this.state.productlist;

        if (data && data.length !== 0) { //
            return (<table className="styled-table">
                <thead>
                    <tr>
                        <th>&nbsp;&nbsp;ID</th>
                        <th>StockNo</th>
                        <th>NodeGroup</th>
                        <th>Origin</th>
                        <th>Description</th>
                        <th>Colour</th>
                        <th style={{ textAlign: 'right' }}>Length&nbsp;&times;&nbsp;width&nbsp;&nbsp;=&nbsp;</th>
                        <th>Qm</th>
                        <th style={{ width: '80px', textAlign: 'center' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                        // if (index % 2 == 0) {
                        return (<tr key={index}>
                            <td>&nbsp;&nbsp;{item.ID}</td>
                            <td>{item.StockNo}</td>
                            <td>{item.NodeGroup}</td>
                            <td>{item.Origin}</td>
                            <td>{item.Description}</td>
                            <td>{item.Colour}</td>
                            <td style={{ textAlign: 'right' }}>{item.Length}&nbsp;&times;&nbsp;{item.Width}&nbsp;&nbsp;=</td>
                            <td>{item.Qm}</td>
                            <td style={{ width: '80px', textAlign: 'center' }}>
                                <button type="button" className="btn btn-link" onClick={() => this.UserDataPrevFunc(item, index, "import-product")} data-toggle="modal" data-target="#ProductDataModal" style={{ width: '40px', color: '#fe9c01' }}><i className="fas fa-plus"></i></button>
                            </td>
                        </tr>)
                    })}
                </tbody>
            </table >)
        } else {
            return (<div style={{ width: '100%', textAlign: 'center', height: '90px', margin: '20px 0' }}>
                <span style={{ color: 'gray', fontSize: '18px', display: 'block' }}>Record not found.</span>
            </div>)
        }
    }


    ContentOrderProduct = () => {
        const data = this.state.selectedproductlist;

        if (data && data.length !== 0) { //
            return (<table className="styled-table">
                <thead>
                    <tr>
                        <th>&nbsp;&nbsp;Index</th>
                        <th>StockNo</th>
                        <th>NodeGroup</th>
                        <th>Origin</th>
                        <th>Description</th>
                        <th>Colour</th>
                        <th style={{ textAlign: 'right' }}>Length&nbsp;&times;&nbsp;width&nbsp;&nbsp;=&nbsp;</th>
                        <th>Qm</th>
                        <th>Number</th>
                        <th>P/Qm</th>
                        <th>Price</th>
                        <th style={{ width: '80px', textAlign: 'center' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                        // if (index % 2 == 0) {
                        return (<tr key={index}>
                            <td>&nbsp;&nbsp;{index + 1}</td>
                            <td>{item.StockNo}</td>
                            <td>{item.NodeGroup}</td>
                            <td>{item.Origin}</td>
                            <td>{item.Description}</td>
                            <td>{item.Colour}</td>
                            <td style={{ textAlign: 'right' }}>{item.Length}&nbsp;&times;&nbsp;{item.Width}&nbsp;&nbsp;=</td>
                            <td>{item.Qm}</td>
                            <td> <input type="number" class="form-control" onChange={() => this.onChangeInputinputNumber(index)} id={`inputNumber_${index}`} placeholder="Number" /></td>
                            <td> <input type="number" class="form-control" onChange={() => this.onChangeInputPricePerQm(index)} id={`inputPricePerQm_${index}`} placeholder="Price/Qm" /></td>
                            <td> <input type="number" class="form-control" onChange={() => this.onChangeInputPrice(index)} id={`inputTotalPrice_${index}`} placeholder="Price" /></td>
                            <td style={{ width: '80px', textAlign: 'center' }}>
                                <button type="button" className="btn btn-link" onClick={() => this.UserDataPrevFunc(item, index, "remove-product-from-list")} data-toggle="modal" data-target="#ViewDataModal" style={{ width: '40px', color: '#fe9c01' }}><i className="fas fa-times"></i></button>
                            </td>
                        </tr>)
                    })}
                </tbody>
            </table >)
        } else {
            return (<div style={{ width: '100%', textAlign: 'center', height: '90px', margin: '20px 0' }}>
                <span style={{ color: 'gray', fontSize: '18px', display: 'block' }}>empty product set.</span>
            </div>)
        }
    }

    onChangeInputinputNumber = (index) => {

        if (document.getElementById("inputNumber_" + index).value === "")
            document.getElementById("inputNumber_" + index).value = 1
        if (document.getElementById("inputNumber_" + index).value < 1)
            document.getElementById("inputNumber_" + index).value = 1

        this.state.selectedproductlist[index].Number = Number(document.getElementById("inputNumber_" + index).value)

        if (document.getElementById("inputPricePerQm_" + index).value !== "") {
            let totalprice = this.state.selectedproductlist[index].Qm * Number(document.getElementById("inputNumber_" + index).value) * Number(document.getElementById("inputPricePerQm_" + index).value)
            document.getElementById("inputTotalPrice_" + index).value = Math.round(totalprice * 100) / 100
        }
        this.SumTotlaPrice()
    }

    onChangeInputPricePerQm = (index) => {

        if (document.getElementById("inputNumber_" + index).value === "")
            document.getElementById("inputNumber_" + index).value = 1

        this.state.selectedproductlist[index].Number = Number(document.getElementById("inputNumber_" + index).value)
        this.state.selectedproductlist[index].PQm = Number(document.getElementById("inputPricePerQm_" + index).value)


        let totalprice = this.state.selectedproductlist[index].Qm * Number(document.getElementById("inputNumber_" + index).value) * Number(document.getElementById("inputPricePerQm_" + index).value)
        document.getElementById("inputTotalPrice_" + index).value = Math.round(totalprice * 100) / 100
        this.state.selectedproductlist[index].TotalPrice = Math.round(totalprice * 100) / 100
        this.SumTotlaPrice()
    }

    onChangeInputPrice = (index) => {

        if (document.getElementById("inputNumber_" + index).value === "")
            document.getElementById("inputNumber_" + index).value = 1

        this.state.selectedproductlist[index].Number = Number(document.getElementById("inputNumber_" + index).value)
        // this.state.selectedproductlist[index].PQm = Number(document.getElementById("inputPricePerQm_" + index).value)
        let totalprice = document.getElementById("inputTotalPrice_" + index).value
        document.getElementById("inputPricePerQm_" + index).value = Math.round((totalprice / (this.state.selectedproductlist[index].Qm * Number(document.getElementById("inputNumber_" + index).value))) * 100) / 100

        document.getElementById("inputTotalPrice_" + index).value = Math.round(totalprice * 100) / 100
        this.state.selectedproductlist[index].TotalPrice = Math.round(totalprice * 100) / 100
        this.SumTotlaPrice()
    }

    //sd

    SumTotlaPrice = () => {
        console.log(this.state.selectedproductlist.length)
        var iTotalPrice = 0;
        var iTotalQm = 0;
        for (let i = 0; i < this.state.selectedproductlist.length; i++) {
            if (document.getElementById("inputTotalPrice_" + i).value !== "") {
                iTotalPrice = iTotalPrice + Number(document.getElementById("inputTotalPrice_" + i).value)
                iTotalQm = iTotalQm + (Number(this.state.selectedproductlist[i].Qm) * Number(document.getElementById("inputNumber_" + i).value))
            }
        }
        document.getElementById("divTotalPrice").innerHTML = "Total Price&nbsp;:&nbsp;" + Math.round(iTotalPrice * 100) / 100
        document.getElementById("divTotalNumber").innerHTML = "Total Number&nbsp;:&nbsp;" + this.state.selectedproductlist.length
        document.getElementById("divTotalQm").innerHTML = "Total Qm&nbsp;:&nbsp;" + Math.round(iTotalQm * 100) / 100

        this.state.OrderNew.TotalNumber = this.state.selectedproductlist.length
        this.state.OrderNew.TotalPrice = Math.round(iTotalPrice * 100) / 100
        this.state.OrderNew.TotalQm = Math.round(iTotalQm * 100) / 100


    }

    SumTotlaPrice_RefreshCalculator = (index) => {

        var iTotalPrice = 0;
        var iTotalQm = 0;
        for (let i = 0; i < this.state.selectedproductlist.length; i++) {
            if (document.getElementById("inputTotalPrice_" + i).value !== "") {
                if (i >= index) {
                    document.getElementById("inputNumber_" + i).value = this.state.selectedproductlist[i].Number
                    document.getElementById("inputPricePerQm_" + i).value = this.state.selectedproductlist[i].PQm
                    document.getElementById("inputTotalPrice_" + i).value = this.state.selectedproductlist[i].TotalPrice
                }
                iTotalPrice = iTotalPrice + Number(document.getElementById("inputTotalPrice_" + i).value)
                iTotalQm = iTotalQm + (Number(this.state.selectedproductlist[i].Qm) * Number(document.getElementById("inputNumber_" + i).value))
            }
        }
        document.getElementById("divTotalPrice").innerHTML = "Total Price&nbsp;:&nbsp;" + Math.round(iTotalPrice * 100) / 100
        document.getElementById("divTotalNumber").innerHTML = "Total Number&nbsp;:&nbsp;" + this.state.selectedproductlist.length
        document.getElementById("divTotalQm").innerHTML = "Total Qm&nbsp;:&nbsp;" + Math.round(iTotalQm * 100) / 100

        this.state.OrderNew.TotalNumber = this.state.selectedproductlist.length
        this.state.OrderNew.TotalPrice = Math.round(iTotalPrice * 100) / 100
        this.state.OrderNew.TotalQm = Math.round(iTotalQm * 100) / 100

    }


    setProduct = (savemod) => {
        this.state.OrderNew.Currency = document.getElementById('selectCurrency').value

        // delete customer from server
        const Token = window.localStorage.getItem('MainLogInTOKEN');
        this.state.OrderNew.Products = this.state.selectedproductlist;
        console.log(this.state.OrderNew)
        var context = this;
        Axios.post('https://b2b.yborhani.de/api/setProduct.php', this.state.OrderNew, { headers: { 'Token': Token } }).then(Response => {
            if (Response.data.code === 0) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    text: Response.data.message,
                    showConfirmButton: false,
                    timer: 3500,
                });
                context.state.OrderNew.CustomerID = null
                context.setState({ selectedproductlist: null, selectedIndex: null, haveProducts: false, haveCustomer: false, previewuserdata: null });
                document.getElementById("divCusomerInfo").style.display = 'none'
                document.getElementById("divCusomerEmpty").style.display = ''
                if (savemod === "save-export") {
                    window.open('/facture?oid=' + Response.data.oid, '_blank', 'noopener,noreferrer');
                }

            } else if (Response.data.code === 5) {
                window.localStorage.setItem('MainLogInTOKEN', "")
                window.location = "/login"
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    text: Response.data.message,
                    showConfirmButton: false,
                    timer: 3500,
                });
            }
        })
    }

    editCustomer = (item, index) => {
        // delete customer from server
        const Token = window.localStorage.getItem('MainLogInTOKEN');

        this.state.userlist[index].Name1 = document.getElementById("Name1").value
        this.state.userlist[index].Name2 = document.getElementById("Name2").value
        this.state.userlist[index].Stadt = document.getElementById("Stadt").value
        this.state.userlist[index].Strasse = document.getElementById("Strasse").value
        this.state.userlist[index].PLZ = document.getElementById("PLZ").value
        this.state.userlist[index].Land = document.getElementById("Land").value
        this.state.userlist[index].Tel1 = document.getElementById("Tel1").value
        this.state.userlist[index].Tel2 = document.getElementById("Tel2").value
        this.state.userlist[index].Fax = document.getElementById("Fax").value
        this.state.userlist[index].WWW = document.getElementById("WWW").value
        this.state.userlist[index].TaxID = document.getElementById("TaxID").value
        this.state.userlist[index].Email = document.getElementById("Email").value

        Axios.post('https://b2b.yborhani.de/api/editCustomer.php', item, { headers: { 'Token': Token } }).then(Response => {
            console.log(Response.data)
            if (Response.data.code === 0) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    text: "Successfully done!",
                    showConfirmButton: false,
                    timer: 3500,
                });
            } else if (Response.data.code === 5) {
                window.localStorage.setItem('MainLogInTOKEN', "")
                window.location = "/login"
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    text: Response.data.message,
                    showConfirmButton: false,
                    timer: 3500,
                });
            }
        })

        // remove item from data list
        try {
            this.setState({ userlist: this.state.userlist });
        } catch (error) {
            console.log(error)
        }
    }

    setCustomerInfo = () => {
        return (<div>
            <div id="divCusomerInfo" >
                <div class="form-row">
                    <div id="divCustomerID" class="form-group col-md-6">
                        Customer ID:
                    </div>
                    <div id="divCustomerNummer" class="form-group col-md-6">
                        Customer Nummer:
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-3">
                        <input type="text" maxLength="50" class="form-control" id="viewName1" placeholder="Name1" />
                    </div>
                    <div class="form-group col-md-3">
                        <input type="text" maxLength="50" class="form-control" id="viewName2" placeholder="Name2" />
                    </div>
                    <div class="form-group col-md-3">
                        <input type="text" class="form-control" id="viewTel1" placeholder="Tel1" />
                    </div>
                    <div class="form-group col-md-3">
                        <input type="text" class="form-control" id="viewTel2" placeholder="Tel2" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-3">
                        <input type="text" class="form-control" id="viewStrasse" placeholder="Strasse" />
                    </div>
                    <div class="form-group col-md-3">
                        <input type="text" class="form-control" id="viewStadt" placeholder="Stadt" />
                    </div>
                    <div class="form-group col-md-3">
                        <input type="text" class="form-control" id="viewLand" placeholder="Land" />
                    </div>
                    <div class="form-group col-md-3">
                        <input type="text" class="form-control" id="viewPLZ" placeholder="PLZ" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-3">
                        <input type="text" class="form-control" id="viewEmail" placeholder="Email" />
                    </div>
                    <div class="form-group col-md-3">
                        <input type="text" class="form-control" id="viewWWW" placeholder="WWW" />
                    </div>
                    <div class="form-group col-md-3">
                        <input type="text" class="form-control" id="viewFax" placeholder="Fax" />
                    </div>
                    <div class="form-group col-md-3">
                        <input type="text" class="form-control" id="viewTaxID" placeholder="TaxID" />
                    </div>
                </div>
            </div>

        </div>);

    }

    setProductList = () => {

        if (this.state.haveProducts) {
            return (<div>
                {this.ContentOrderProduct()}

                <div class="form-row mt-2">
                    <div class="form-group col-md-3">
                        <div id="divTotalNumber" className="mt-2" style={{ textAlign: "center" }}>Total Number&nbsp;:&nbsp;</div>
                    </div>
                    <div class="form-group col-md-3">
                        <div id="divTotalQm" className="mt-2" style={{ textAlign: "center" }}>Total Qm&nbsp;:&nbsp;</div>
                    </div>
                    <div class="form-group col-md-3">
                        <div id="divTotalPrice" className="mt-2" style={{ textAlign: "right" }}>Total Price&nbsp;:&nbsp;</div>
                    </div>
                    <div class="form-group col-md-3">
                        <select class="custom-select mr-sm-2" id="selectCurrency">
                            <option value="EUR" selected="selected">EUR</option>
                            <option value="USD">USD</option>
                        </select>
                    </div>
                </div>

            </div>);
        } else {
            return (<div>
                Add some products to order!
            </div>);
        }
    }

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-light mb-2">
                    <div className="container-fluid">
                        <h2>Import New Product To Inventory</h2>
                        <ul className="nav navbar-nav ml-auto">
                            <li className="nav-item">
                                {/* <button type="button" className="btn btn-warning" data-toggle="modal" data-target="#SearchCustomerDataModal" style={{ width: '200px', color: '#ffffff' }}><i className="fas fa-plus"></i>&nbsp;&nbsp;Import Customer</button> */}
                            </li>
                        </ul>
                    </div>
                </nav>

                <div className="card p-3 mb-4">
                    {this.setCustomerInfo()}
                </div>

                <nav className="navbar navbar-expand-lg navbar-light mt-5">
                    <div className="container-fluid">
                        <ul className="nav navbar-nav ml-auto">
                            <li className="nav-item">
                                <button type="button" onClick={() => this.setProduct("save")} className="btn btn-success" style={{ width: '200px', color: '#ffffff' }}><i className="fas fa-save"></i>&nbsp;&nbsp;Save Product</button>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        )
    }
}

export default InventoryNew;