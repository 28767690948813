import { combineReducers } from 'redux';

// function UsernameReducer(state = null, action) {
//     if (action.type === 'USERNAME_ACT') {
//         return action.payload;
//     } else {
//         return state;
//     }
// }

// function SideBarItemsReducer(state = null, action) {
//     if (action.type === 'SIDEBAR_ITEMS_ACT') {
//         return action.payload;
//     } else {
//         return state;
//     }
// }

// function MainContentReducer(state = null, action) {
//     if (action.type === 'MAIN_CONTENT_ACT') {
//         return action.payload;
//     } else {
//         return state;
//     }
// }

// function UserListReducer(state = null, action) {
//     if (action.type === 'USER_LIST_ACT') {
//         return action.payload;
//     } else {
//         return state;
//     }
// }

// function EditingUserDataReducer(state = null, action) {
//     if (action.type === 'EDITING_USER_DATA_ACT') {
//         return action.payload;
//     } else {
//         return state;
//     }
// }

function AddDataReducer(state = {}, action) {
    if (action.type === "GLOBAL_STORAGE") {
        return { ...state, globalState: action.payload };
    } else {
        return state;
    }
}

export default combineReducers({
    // username: UsernameReducer,
    // sidebaritems: SideBarItemsReducer,
    // maincontentindex: MainContentReducer,
    // userlist: UserListReducer,
    // editinguserdata: EditingUserDataReducer,
    globalstate: AddDataReducer
})