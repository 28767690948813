import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

// import { DigitAnyToEn } from '../../utility/common.js';

class Login extends React.Component {

    state = {
        mobile: ""
    };


    login = () => {
        const token = window.sessionStorage.getItem('SatCPToken');
        const username = document.getElementById("txtusername").value;
        const password = document.getElementById("txtpassword").value;

        axios.post('https://b2b.yborhani.de/api/login.php', { "username": username, "password": password }, { headers: { Token: password } }).then(Response => {
            if (Response.data.code === 0) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    text: Response.data.message,
                    showConfirmButton: false,
                    timer: 3500,
                });

                window.localStorage.setItem('MainLogInTOKEN', Response.data.token);
                document.location = '/';

            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    text: Response.data.message,
                    showConfirmButton: false,
                    timer: 3500,
                });
            }
        })
    }

    render() {
        return (
            <div>
                <div className="container relativity">
                    <h3 className="text-left my-3" style={{ paddingTop: '80px' }}>Welcome to B2B</h3>
                    <div className="card card-default text-center">
                        <div className="card-header font-18"> Plase enter your email/phone</div>
                        <div className="card-block" style={{ height: '160px' }}>
                            <input id="txtusername" type="text" maxLength="50" className="form-control border-orange font-20" placeholder="Email/Phone" style={{ textAlign: 'center', direction: "ltr", width: '270px', marginTop: '30px', display: 'inline-block' }} />
                            <br />
                            <input id="txtpassword" type="password" maxLength="50" className="form-control border-orange font-20" placeholder="Password" style={{ textAlign: 'center', direction: "ltr", width: '270px', marginTop: '30px', display: 'inline-block' }} />

                        </div>

                        <div className="card-footer">
                            <button onClick={this.login} className="btn btn-warning font-18 bg-orange m-2">
                                login
                        </button>
                        </div>
                    </div>


                </div>
                <div className="footer  pt-3">
                    Copyright: All Rights Reserved
                </div>
            </div>
        );
    }
}

export default Login;